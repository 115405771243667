import * as React from "react"
import styled from "styled-components"
import { SplitPane } from "@components/layout"
import { palette } from "@theme"
import { BlockRenderer } from "@components/primitives"
import { Pill } from "@components/images"

interface RevelryIntroductionProps {
  inFocus: boolean
  introduction: GatsbyTypes.Maybe<
    ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.SanityContentBlockWithShapes>>
  >
  activeProject: string
}

const TangerineIntroduction = ({
  inFocus,
  introduction,
  activeProject,
  ...props
}: RevelryIntroductionProps) => {
  return (
    <Wrapper
      left={activeProject === "tangerine"}
      {...props}
      {...{ inFocus, activeProject }}
    >
      <BlockRenderer>{introduction}</BlockRenderer>
      <StyledPill color={palette.ginger} />
    </Wrapper>
  )
}

const Wrapper = styled(props => <SplitPane {...props} />)<{
  activeProject: string
}>`
  position: relative;
  grid-column: ${props => (props.activeProject === "tangerine" ? "1" : "2")};
  grid-row: 1;
  padding: 10vw 20vw;
  overflow: hidden;
  background-color: ${palette.ginger};
  color: ${palette.rhubarb};
  text-align: center;
  p {
    margin: 1.5em 0;
  }
`

const StyledPill = styled(props => <Pill {...props} />)`
  width: 120vw;
  transform: rotateZ(75deg);
  @media only screen and (min-width: 744px) {
    width: 97vw;
    transform: rotateZ(5deg);
  }
  position: absolute;
  z-index: -1;
  path {
    stroke: ${palette.grapefruit};
    stroke-width: 1px;
  }
`

export default TangerineIntroduction
