import * as React from "react"
import styled from 'styled-components'
import { Pill } from './'

interface PillProps {
  color: string
  transparent?: boolean
  mobileTransparent?: boolean
  forceShrink?: number
  hideDesktop?: boolean
}


 
const ShrinkingPill = ({ color, transparent, mobileTransparent, forceShrink, hideDesktop }: PillProps) => {
  const [scale, setScale] = React.useState(1)
  React.useEffect(() => {
    setScale(forceShrink ? forceShrink : 1)
  }, [forceShrink])
  const setScaleIf = (s: number) => !forceShrink && setScale(s)
  return (
    <Wrapper onMouseLeave={() => setScale(1)} {...{ hideDesktop, transparent, mobileTransparent }} >
        <StyledPill {...{ color, scale }}/>
        <Hover size={1}  onMouseEnter={() => setScaleIf(0.6)} />
        <Hover size={.8} onMouseEnter={() => setScaleIf(0.45)} />
        <Hover size={.6} onMouseEnter={() => setScaleIf(0.3)} />
        <Hover size={.4} onMouseEnter={() => setScaleIf(0.1)} />
        <Hover size={.2} onMouseEnter={() => setScaleIf(0)} />
    </Wrapper>
  )
}


const StyledPill = styled(props => <Pill {...props} />).attrs(
  ({ scale }: { scale: number }) => {
  return { style: {
    transform: `scale( ${scale} )`
  } }}
)<{ color: string }>`
  position: relative;
  z-index: 0;
  width: 100% !important;
  height: 100% !important;
  transition: transform 0.5s cubic-bezier(.47,1.64,.41,.8);
  transform-origin: center center;
`



// note: transform is attrs
const Wrapper = styled.div<{
  hideDesktop?: boolean,
  transparent?: boolean,
  mobileTransparent?: boolean,
}>`
  position: relative;
  z-index: 1;
  width: 100%;
  @media only screen and (max-width: 743px) {
    ${props => props.mobileTransparent && `opacity: 0;`}
  }
  @media only screen and (min-width: 744px) {
    ${props => (props.hideDesktop) && `display: none;`}
    ${props => (props.transparent) && `opacity: 0;`}
  }
`

const Hover = styled.div<{ size: number }>`
  position: absolute;
  z-index: 2;
  width: ${props => props.size * 100}%;
  height: ${props => props.size * 100}%;
  left: ${props => (1 - props.size) * 50}%;
  top: ${props => (1 - props.size) * 50}%;
`


export default ShrinkingPill