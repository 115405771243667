import * as React from "react"
import styled from "styled-components"
import { SplitPane } from "@components/layout"
import { BlockRenderer } from "@components/primitives"

interface IntroductionProps {
  inFocus: boolean
  content: GatsbyTypes.Maybe<
    ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.SanityContentBlockWithShapes>>
  >
}

const Introduction = ({ inFocus, content, ...props }: IntroductionProps) => {
  const activeProject = process.env.GATSBY_PRIMARY_SITE
  return (
    <StyledSplitPane left {...props} {...{ inFocus, activeProject }}>
      <BlockRenderer>{content}</BlockRenderer>
    </StyledSplitPane>
  )
}

const StyledSplitPane = styled(props => <SplitPane {...props} />)<{
  activeProject: string
}>`
  p {
    opacity: ${props => (props.inFocus ? 1 : 0)};
    transform: scale(${props => (props.inFocus ? 1 : 0.8)});
    transition: opacity 0.3s ease-in-out,
      transform 1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  }
  grid-row: 1;
`

export default Introduction
