import * as React from "react"
import styled from "styled-components"
import { SplitPane } from "@components/layout"
import { TangerineLogo, ShrinkingPill } from "@components/images"
import { palette } from "@theme"

const TangerineHeader = (props: any) => {
  const activeProject = process.env.GATSBY_PRIMARY_SITE
  const [hoverTitle, setHoverTitle] = React.useState(false)
  return (
    <StyledSplitPane
      left={activeProject === "tangerine"}
      {...props}
      {...{ activeProject }}
    >
      <Title
        onMouseEnter={() => setHoverTitle(true)}
        onMouseLeave={() => setHoverTitle(false)}
      >
        <StyledTangerineLogo
          color={palette.ginger}
          colorDot={palette.tangerine}
        />
      </Title>
      <Pills>
        {Array.from(Array(28).keys()).map((_, i) => (
          <ShrinkingPill
            key={`pill_${i}`}
            mobileTransparent={[12, 13, 14, 15].includes(i)}
            transparent={i > 10 && i < 14}
            forceShrink={
              !hoverTitle
                ? undefined
                : [6, 7, 8, 16, 17, 18].includes(i)
                ? 0.4
                : [10, 14].includes(i)
                ? 0.6
                : [5, 9, 15, 19].includes(i)
                ? 0.7
                : [1, 2, 3, 5, 15, 21, 22, 23].includes(i)
                ? 0.8
                : undefined
            }
            color={palette.peach}
            hideDesktop={i > 24}
          />
        ))}
      </Pills>
    </StyledSplitPane>
  )
}

const Title = styled.div`
  position: absolute;
  z-index: 2;

  width: 70vw;
  @media only screen and (min-width: 744px) {
    width: 60vw;
  }
  @media only screen and (min-width: 1120px) {
    width: 34vw;
  }

  transform: scale(0.8);
  transition: transform 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transform-origin: center center;
  will-change: transform;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1);
  }
`

const StyledTangerineLogo = styled(props => <TangerineLogo {...props} />)`
  width: 100%;
`

const StyledSplitPane = styled(props => <SplitPane {...props} />)<{
  activeProject: string
}>`
  background-color: ${palette.rhubarb};
  grid-row: 1;
  grid-column: ${props => (props.activeProject === "tangerine" ? "1" : "2")};
`

const Pills = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 1vw;
  grid-row-gap: 1vw;
  padding: 2vw;

  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    padding: 2vw;
  }
`

export default TangerineHeader
