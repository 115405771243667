import * as React from "react"
import styled from "styled-components"
import { RevelryLogo, Lump } from "@components/images"
import { palette } from "@theme"

interface RevelryHeaderProps {
  visible: boolean
  scrolled: boolean
  left?: boolean
  className?: string
}

const RevelryHeader = ({
  visible,
  scrolled,
  className,
  left,
  ...props
}: RevelryHeaderProps) => {
  const scrollToTop = () => window.scrollTo({ behavior: "smooth", top: 0 })
  return (
    <Wrapper
      {...{ visible, scrolled, left, className }}
      {...props}
      onClick={() => scrollToTop()}
    >
      <StyledRevelryLogo />
      <StyledLump color={palette.pig} />
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  visible: boolean
  scrolled: boolean
  left?: boolean
}>`
  position: absolute;
  z-index: 3;
  ${props => (props.left ? "left: 2vw;" : "right: 0;")}
  width: 22vw;
  height: 12vw;
  ${props =>
    props.left
      ? `
    transform: ${
      props.visible && props.scrolled ? "translateX(4vw)" : "translateX(-35vw)"
    };
  `
      : `
    transform: ${
      props.visible && props.scrolled ? "translateX(-7vw)" : "translateX(30vw)"
    };
  `}
  @media only screen and (min-width: 744px) {
    ${props => (props.left ? "left: 0;" : "right: 0;")}
    width: 20vw;
    height: 12vw;
    ${props =>
      props.left
        ? `
      transform: ${
        props.visible && props.scrolled
          ? "translateX(4vw)"
          : "translateX(-20vw)"
      };
    `
        : `
      transform: ${
        props.visible && props.scrolled ? "translateX(0vw)" : "translateX(25vw)"
      };
    `}
  }
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`

const StyledRevelryLogo = styled(props => <RevelryLogo {...props} />)`
  position: absolute;
  z-index: 4;
  width: 24vw;
  top: 3vw;
  left: 0vw;
  @media only screen and (min-width: 744px) {
    top: 2.25vw;
    width: 12vw;
  }
`

const StyledLump = styled(props => <Lump {...props} />)`
  position: absolute;
  z-index: 2;
  width: 30vw;
  top: -14vw;
  left: 4vw;
  transform: rotateZ(-90deg) scaleX(-1);
  transition: top 0.5s ease-in-out, width 0.5s ease-in-out;
  @media only screen and (min-width: 744px) {
    width: 15vw;
    top: -12vw;
    left: 2vw;
  }
`

export default RevelryHeader
