import React from 'react'
import { Svg, Path } from './'


const TangerineLogo = ({ color, colorDot, className }: { color?: string, colorDot?: string, className?: string }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 464 104"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...{ className }}
  >
    <g transform="matrix(1,0,0,1,24.6,7.5169)">
      <Path {...{ color }} d="M0,72.012C-11.53,72.012 -16.9,65.301 -16.9,50.892L-16.9,32.806C-16.9,31.692 -17.795,30.789 -18.898,30.789L-22.602,30.789C-23.705,30.789 -24.6,29.886 -24.6,28.772L-24.6,14.613C-24.6,13.499 -23.705,12.596 -22.602,12.596L-18.898,12.596C-17.795,12.596 -16.9,11.693 -16.9,10.579L-16.9,2.016C-16.9,0.903 -16.006,0 -14.903,0L-7.09,0C-5.987,0 -5.092,0.903 -5.092,2.016L-5.092,10.579C-5.092,11.693 -4.198,12.596 -3.094,12.596L9.404,12.596C10.508,12.596 11.402,13.499 11.402,14.613L11.402,28.772C11.402,29.886 10.508,30.789 9.404,30.789L-3.094,30.789C-4.198,30.789 -5.092,31.692 -5.092,32.806L-5.092,45.166C-5.092,48.564 -3.719,54.266 5.488,54.266C6.304,54.266 7.172,54.221 8.088,54.131C9.27,54.015 10.303,54.933 10.303,56.132L10.303,68.852C10.303,69.742 9.739,70.529 8.894,70.785C6.695,71.453 3.738,72.012 0,72.012" />
    </g>
    <g transform="matrix(1,0,0,1,69.2059,61.3358)">
      <Path {...{ color }} d="M0,-24.175C-11.133,-24.175 -17.518,-19.77 -17.518,-12.088C-17.518,-4.406 -11.133,0 0,0C10.968,0 17.516,-4.519 17.516,-12.088C17.516,-19.657 10.968,-24.175 0,-24.175M-3.078,18.193C-23.41,18.193 -27.678,1.726 -27.678,-12.088C-27.678,-25.902 -23.41,-42.368 -3.078,-42.368C1.2,-42.368 9.073,-41.421 13.728,-35.084L16.967,-30.673L16.967,-39.206C16.967,-40.32 17.861,-41.223 18.965,-41.223L26.779,-41.223C27.882,-41.223 28.777,-40.32 28.777,-39.206L28.777,15.031C28.777,16.145 27.882,17.048 26.779,17.048L18.965,17.048C17.861,17.048 16.967,16.145 16.967,15.031L16.967,6.499L13.728,10.909C9.073,17.246 1.2,18.193 -3.078,18.193" />
    </g>
    <g transform="matrix(1,0,0,1,147.86,18.9679)">
      <Path {...{ color }} d="M0,59.416C-1.103,59.416 -1.998,58.513 -1.998,57.399L-1.998,36.006C-1.998,23.176 -9.251,20.483 -15.335,20.483C-21.922,20.483 -29.772,23.176 -29.772,36.006L-29.772,57.399C-29.772,58.513 -30.666,59.416 -31.77,59.416L-39.586,59.416C-40.689,59.416 -41.584,58.513 -41.584,57.399L-41.584,3.162C-41.584,2.048 -40.689,1.145 -39.586,1.145L-31.77,1.145C-30.666,1.145 -29.772,2.048 -29.772,3.162L-29.772,8.086C-29.772,9.015 -29.143,9.824 -28.25,10.045L-27.842,10.146C-27.017,10.35 -26.147,10.014 -25.698,9.286C-22.928,4.797 -17.733,0 -8.187,0C4.094,0 9.814,6.711 9.814,21.12L9.814,57.399C9.814,58.513 8.92,59.416 7.816,59.416L0,59.416Z" />
    </g>
    <g transform="matrix(1,0,0,1,253.679,65.9158)">
      <Path {...{ color }} d="M0,-33.335C-8.233,-33.335 -14.333,-29.888 -16.319,-24.115C-16.717,-22.96 -15.859,-21.756 -14.637,-21.756L14.198,-21.756C15.42,-21.756 16.278,-22.96 15.88,-24.115C13.863,-29.975 8.074,-33.335 0,-33.335M4.399,13.613C-16.37,13.613 -27.349,3.142 -27.349,-16.668C-27.349,-35.346 -16.449,-46.948 1.099,-46.948C10.216,-46.948 17.093,-44.373 21.538,-39.297C26.223,-33.946 28.122,-26.009 27.199,-15.671C27.108,-14.648 26.232,-13.869 25.205,-13.869L-14.967,-13.868C-16.131,-13.868 -16.982,-12.77 -16.69,-11.643C-14.888,-4.677 -8.163,-1.145 3.3,-1.145C11.928,-1.145 17.905,-2.185 22.646,-3.355C23.919,-3.669 25.148,-2.715 25.148,-1.404L25.148,7.356C25.148,8.51 24.457,9.542 23.402,10.01C18.018,12.403 11.641,13.613 4.399,13.613" />
    </g>
    <g transform="matrix(1,0,0,1,289.193,21.5629)">
      <Path {...{ color }} d="M0,54.804L0,0.567C0,-0.547 0.894,-1.45 1.998,-1.45L9.812,-1.45C10.916,-1.45 11.81,-0.547 11.81,0.567L11.81,6.548C11.81,7.517 12.492,8.349 13.435,8.53L13.711,8.583C14.621,8.758 15.502,8.264 15.909,7.424C18.859,1.32 23.851,-2.017 30.108,-2.017C31.111,-2.017 32.146,-1.93 33.205,-1.758C34.179,-1.6 34.901,-0.751 34.901,0.244L34.901,17.358C34.901,18.539 33.908,19.464 32.742,19.364C31.5,19.257 30.197,19.185 28.899,19.185C17.56,19.185 11.81,25.128 11.81,36.846L11.81,54.804C11.81,55.918 10.916,56.821 9.812,56.821L1.998,56.821C0.894,56.821 0,55.918 0,54.804" />
    </g>
    <g transform="matrix(1,0,0,1,331.375,22.1299)">
      <Path {...{ color }} d="M0,54.237L0,0C0,-1.114 0.894,-2.017 1.998,-2.017L9.814,-2.017C10.917,-2.017 11.812,-1.114 11.812,0L11.812,54.237C11.812,55.351 10.917,56.254 9.814,56.254L1.998,56.254C0.894,56.254 0,55.351 0,54.237" />
    </g>
    <g transform="matrix(1,0,0,1,392.709,18.9679)">
      <Path {...{ color }} d="M0,59.416C-1.103,59.416 -1.998,58.513 -1.998,57.399L-1.999,36.006C-1.999,23.176 -9.253,20.483 -15.337,20.483C-21.923,20.483 -29.773,23.176 -29.773,36.006L-29.773,57.399C-29.773,58.513 -30.668,59.416 -31.771,59.416L-39.586,59.416C-40.689,59.416 -41.583,58.513 -41.583,57.399L-41.583,3.162C-41.583,2.048 -40.689,1.145 -39.586,1.145L-31.771,1.145C-30.668,1.145 -29.773,2.048 -29.773,3.162L-29.773,8.086C-29.773,9.015 -29.145,9.824 -28.251,10.045L-27.843,10.146C-27.018,10.35 -26.148,10.014 -25.699,9.286C-22.93,4.797 -17.735,0 -8.189,0C4.092,0 9.812,6.711 9.812,21.12L9.812,57.399C9.812,58.513 8.918,59.416 7.815,59.416L0,59.416Z" />
    </g>
    <g transform="matrix(1,0,0,1,436.294,65.9158)">
      <Path {...{ color }} d="M0,-33.335C-8.233,-33.335 -14.333,-29.888 -16.319,-24.115L-16.32,-24.114C-16.717,-22.959 -15.858,-21.756 -14.637,-21.756L14.198,-21.756C15.42,-21.756 16.278,-22.96 15.88,-24.115C13.863,-29.975 8.076,-33.335 0,-33.335M4.399,13.613C-16.37,13.613 -27.347,3.142 -27.347,-16.668C-27.347,-35.346 -16.447,-46.948 1.099,-46.948C10.216,-46.948 17.093,-44.373 21.538,-39.297C26.223,-33.946 28.122,-26.009 27.199,-15.671C27.108,-14.648 26.232,-13.869 25.205,-13.869L-14.967,-13.868C-16.131,-13.868 -16.982,-12.77 -16.69,-11.643C-14.888,-4.677 -8.163,-1.145 3.3,-1.145C11.928,-1.145 17.905,-2.185 22.646,-3.355C23.919,-3.669 25.148,-2.715 25.148,-1.404L25.148,7.356C25.148,8.51 24.457,9.542 23.402,10.01C18.018,12.403 11.641,13.613 4.399,13.613" />
    </g>
    <g transform="matrix(1,0,0,1,192.039,64.006)">
      <Path {...{ color }} d="M0,-2.67C-11.133,-2.67 -17.518,-7.076 -17.518,-14.758C-17.518,-22.44 -11.133,-26.845 0,-26.845C10.968,-26.845 17.516,-22.327 17.516,-14.758C17.516,-7.189 10.968,-2.67 0,-2.67M28.423,5.455L28.423,-41.625C28.423,-42.739 27.528,-43.643 26.425,-43.643L19.708,-43.643C18.604,-43.643 17.71,-42.739 17.71,-41.625L17.71,-35.89C17.71,-34.939 17.052,-34.118 16.131,-33.918L15.801,-33.847C14.91,-33.654 14.029,-34.107 13.597,-34.916C9.914,-41.812 3.972,-45.038 -4.974,-45.038C-23.131,-45.038 -28.168,-28.706 -28.168,-14.72C-28.168,-0.734 -23.131,15.598 -4.974,15.598C3.972,15.598 9.914,12.372 13.597,5.476C14.029,4.667 14.91,4.214 15.801,4.406L16.307,4.516C17.09,4.685 17.47,5.436 17.469,6.246C17.457,26.989 -6.02,25.729 -20.012,21.812C-21.653,21.352 -24.651,20.909 -24.651,23.419L-24.651,32.6C-24.651,33.748 -24.009,34.817 -22.971,35.285C-7.286,42.368 17.094,41.344 25.27,23.54C28.41,16.701 28.447,9.18 28.447,1.8L28.423,5.455Z" />
    </g>
    <g transform="matrix(-1,0,0,1,337.649,13.803)">
      <Path color={colorDot || color} d="M0.735,-13.803C6.979,-13.803 11.753,-11.831 11.753,-6.901C11.753,-1.972 6.979,0 0.735,0C-6.243,0 -11.018,-1.972 -11.018,-6.901C-11.018,-11.831 -6.243,-13.803 0.735,-13.803" />
    </g>
  </Svg>
)



export default TangerineLogo