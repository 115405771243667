import * as React from "react"
import { Svg, Path } from './'


const Pill = ({ color, style, className, children }: { color?: string, style?: any, className?: string, children?: React.ReactNode }) => (
  <Svg width="100%" height="100%" viewBox="0 0 359 218" version="1.1" xmlns="http://www.w3.org/2000/svg" {...{ className, style }} >
    <g transform="matrix(-1,0,0,1,184.828,217.069)">
        <Path {...{ color }} d="M11.551,-217.069C109.74,-217.069 184.828,-186.059 184.828,-108.535C184.828,-31.01 109.74,0 11.551,0C-98.189,0 -173.277,-31.01 -173.277,-108.535C-173.277,-186.059 -98.189,-217.069 11.551,-217.069" />
    </g>
    <text x="0" y="0">{children}</text>
  </Svg>
)





export default Pill