import * as React from "react"
import styled from "styled-components"
import { RevelryHeader, TangerineHeader, PartnerBar } from "./"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

interface HeaderProps {
  flipped: boolean
  activeProject: string
  flip: () => void
}

const Header = ({ flipped, activeProject, flip }: HeaderProps) => {
  const [scrolled, setScrolled] = React.useState(false)
  useScrollPosition(
    ({ currPos }) => {
      if (typeof window === "undefined") return
      setScrolled(currPos.y > window.innerHeight * 0.7)
    }, // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    50 // performance debounce
  )

  const visible = (key: "revelry" | "tangerine") => {
    if (!scrolled) return false
    return (
      (!flipped && activeProject === key) || (flipped && activeProject !== key)
    )
  }

  return (
    <Wrapper>
      <RevelryHeader
        visible={visible("revelry")}
        {...{ scrolled }}
        left={activeProject === "revelry"}
      />
      <TangerineHeader
        visible={visible("tangerine")}
        {...{ scrolled }}
        left={activeProject === "tangerine"}
      />
      <PartnerBar
        {...{ scrolled, flip }}
        projectOnLeft={activeProject}
        viewportOnLeft={!flipped}
      />
    </Wrapper>
  )
}

const Wrapper = styled.header`
  position: fixed;
  z-index: 100;
  overflow: visible;
  top: 0px;
  width: 100%;
  height: 0px;
`

export default Header
