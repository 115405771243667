import * as React from "react"
import { useLocation } from "@reach/router"
import styled from "styled-components"
import { SplitPane } from "@components/layout"
import { BlockRenderer } from "@components/primitives"
import { palette } from "@theme"

interface ContactProps {
  inFocus: boolean
  thisProject: string
  copy: GatsbyTypes.Maybe<
    ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.SanityContentBlockWithShapes>>
  >
  activeProject: string
}

const Contact = ({
  inFocus,
  copy,
  thisProject,
  activeProject,
  ...props
}: ContactProps) => {
  return (
    <Wrapper
      left={thisProject === activeProject}
      {...props}
      {...{ inFocus, thisProject }}
    >
      <BlockRenderer>{copy}</BlockRenderer>
    </Wrapper>
  )
}

const Wrapper = styled(props => <SplitPane {...props} />)<{
  thisProject: string
  left: boolean
}>`
  min-height: 40vh;
  @media only screen and (min-width: 744px) {
    min-height: none;
  }
  position: relative;
  grid-row: 1;
  grid-column: ${props => (props.left ? "1" : "2")};
  padding: 10vw 10vw;
  overflow: hidden;
  text-align: center;
  background-color: ${props =>
    props.thisProject === "tangerine" ? palette.peach : palette.springwood};
  color: ${props =>
    props.thisProject === "tangerine" ? palette.earth : palette.currant};
  p {
    margin: 1.5em 0;
  }
`

export default Contact
