import * as React from "react"
import styled from "styled-components"
import { palette } from "@theme"

interface PartnerBarProps {
  scrolled: boolean
  projectOnLeft: string
  viewportOnLeft: boolean
  flip: () => void
}

const PartnerBar = ({
  projectOnLeft,
  viewportOnLeft,
  flip,
}: PartnerBarProps) => {
  const scrolled = true
  return (
    <Wrapper
      {...{ projectOnLeft, viewportOnLeft, scrolled }}
      onClick={() => flip()}
    >
      <Link
        $onLeft={projectOnLeft === "tangerine"}
        {...{ viewportOnLeft, scrolled }}
      >
        Tangerine
      </Link>
      <Link
        $onLeft={projectOnLeft === "revelry"}
        {...{ viewportOnLeft, scrolled }}
      >
        Revelry
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div<Partial<PartnerBarProps>>`
  position: fixed;
  z-index: 1;
  top: ${props => (props.scrolled ? 0 : -31)}px;
  left: 0;
  width: 100%;
  height: 31px;
  background-color: ${props =>
    (props.projectOnLeft === "revelry" && props.viewportOnLeft) ||
    (props.projectOnLeft === "tangerine" && !props.viewportOnLeft)
      ? palette.darkCurrant
      : palette.earth};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  color: white;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props =>
    props.projectOnLeft === "revelry" ? "row-reverse" : "row"};
  transition: top 0.5s ease-in-out, background-color 0.15s ease-in-out;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const Link = styled.div<
  {
    $onLeft: boolean
  } & Partial<PartnerBarProps>
>`
  opacity: ${props =>
    (props.$onLeft && props.viewportOnLeft) ||
    (!props.$onLeft && !props.viewportOnLeft)
      ? 0
      : 1};
  &:before {
    ${props =>
      props.$onLeft &&
      `
      padding: 0 5px;
      content: '←';
      font-family: 'verdana';
    `}
  }
  &:after {
    ${props =>
      !props.$onLeft &&
      `
      padding: 0 5px;
      content: '→';
      font-family: 'verdana';
    `}
  }
  cursor: pointer;
`

export default PartnerBar
