import * as React from "react"
import styled from "styled-components"
import { SplitPane } from "@components/layout"
import { palette } from "@theme"
import { Lump } from "@components/images"
import { BlockRenderer } from "@components/primitives"

interface RevelryIntroductionProps {
  inFocus: boolean
  introduction: GatsbyTypes.Maybe<
    ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.SanityContentBlockWithShapes>>
  >
  activeProject: string
}

const RevelryIntroduction = ({
  inFocus,
  introduction,
  activeProject,
  ...props
}: RevelryIntroductionProps) => {
  return (
    <Wrapper
      left={activeProject === "revelry"}
      {...props}
      {...{ inFocus, activeProject }}
    >
      <BlockRenderer>{introduction}</BlockRenderer>
      <StyledLump color={palette.moon} />
    </Wrapper>
  )
}

const Wrapper = styled(props => <SplitPane {...props} />)<{
  activeProject: string
}>`
  position: relative;
  grid-column: ${props => (props.activeProject === "revelry" ? "1" : "2")};
  grid-row: 1;
  padding: 10vw 10vw 10vw 40vw;
  overflow: hidden;
  background-color: ${palette.springwood};
  color: ${palette.darkCurrant};
`

const StyledLump = styled(props => <Lump {...props} />)`
  position: absolute;
  bottom: -30vw;
  left: 0vw;
  z-index: -1;
  width: 65vw;
  transform: rotateZ(-90deg);
`

export default RevelryIntroduction
