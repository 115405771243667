import React from "react"
import styled from "styled-components"
import { Portrait, WidePane } from "@components/layout"
import { palette } from "@theme"

interface TeamPhotosPaneProps {
  activeProject: string
  layer: number
  teamMembers: Array<Partial<GatsbyTypes.SanityTeamMember>>
}

const TeamPhotosPane = ({
  activeProject,
  layer,
  teamMembers,
}: TeamPhotosPaneProps) => (
  <BackgroundPane {...{ activeProject, layer }}>
    {teamMembers?.map(
      (peep, i) =>
        !!peep && (
          <Portrait
            {...peep}
            key={`portrait_${i}`}
            rounded={activeProject === "tangerine"}
          />
        )
    )}
  </BackgroundPane>
)

const BackgroundPane = styled(props => <WidePane {...props} />)<{
  activeProject: "revelry" | "tangerine"
}>`
  padding: 10vw 10vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5vw;
  grid-row-gap: 2vw;
  overflow: hidden;
  max-height: ${props =>
    props.activeProject === "revelry" ? "120vw" : "200vh"};
  @media only screen and (min-width: 744px) {
    padding: 4vw 10vw;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2vw;
    grid-row-gap: 4vw;
    max-height: ${props =>
      props.activeProject === "revelry" ? "33vw" : "140vw"};
  }

  background-color: ${props =>
    props.activeProject === "revelry" ? palette.currant : palette.rhubarb};
  box-shadow: none;
  color: ${props =>
    props.activeProject === "revelry" ? palette.rosebud : palette.ginger};
  transition: color 0.5s ease-in-out, background-color 0.75s ease-in-out,
    max-height 1.5s ease-in-out; ;
`

export default TeamPhotosPane
