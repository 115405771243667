import React from 'react'
import { Svg, Path } from './'


const Wave = ({ color, ...props }: { color?: string, }) => (
  <Svg width="100%" height="100%" viewBox="0 0 1336 2227" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
      <g transform="matrix(14.7748,0,0,14.7748,131.555,53.6177)">
          <Path {...{ color }} d="M0,145.232C-3.045,148.861 -8.904,146.801 -8.904,142.066L-8.904,47.226C-8.904,14.939 6.845,-3.629 33.878,-3.629C61.326,-3.629 81.51,16.96 81.51,48.034C81.51,102.667 27.741,112.174 0,145.232" />
      </g>
  </Svg>
)



export default Wave