import * as React from "react"
import styled from "styled-components"
import { SplitPane } from "@components/layout"
import { RevelryLogo, Wave } from "@components/images"
import { palette } from "@theme"

const RevelryHeader = ({ inFocus, ...props }: { inFocus: boolean }) => {
  const activeProject = process.env.GATSBY_PRIMARY_SITE
  const color_one = palette.currant
  const color_two = palette.rosebud

  return (
    <StyledSplitPane
      left={activeProject === "revelry"}
      {...props}
      {...{ inFocus, activeProject }}
      color={color_one}
    >
      <Contents>
        <LogoWrapper>
          <RevelryLogo color={palette.springwood} />
          <Copy>who beats what</Copy>
        </LogoWrapper>
      </Contents>
      <Flip>
        <StyledWave
          pause={!inFocus}
          color={color_two}
          animation="animate_0"
          z={1}
        />
        <StyledWave
          pause={!inFocus}
          color={color_one}
          animation="animate_00"
          z={2}
        />
        <StyledWave
          pause={!inFocus}
          color={color_two}
          animation="animate_1"
          z={3}
        />
        <StyledWave
          pause={!inFocus}
          color={color_one}
          animation="animate_2"
          z={4}
        />
        <StyledWave
          pause={!inFocus}
          color={color_two}
          animation="animate_3"
          z={5}
        />
        <StyledWave
          pause={!inFocus}
          color={color_one}
          animation="animate_4"
          z={6}
        />
        <StyledWave
          pause={!inFocus}
          color={color_two}
          animation="animate_5"
          z={7}
        />
        <StyledWave
          pause={!inFocus}
          color={color_one}
          animation="animate_6"
          z={8}
        />
        <StyledWave
          pause={!inFocus}
          color={color_two}
          animation="animate_7"
          z={9}
        />
        <StyledWave
          pause={!inFocus}
          color={color_one}
          animation="animate_8"
          z={10}
        />
        <StyledWave
          pause={!inFocus}
          color={color_two}
          animation="animate_9"
          z={11}
        />
        <StyledWave
          pause={!inFocus}
          color={color_one}
          animation="animate_10"
          z={12}
        />
      </Flip>
    </StyledSplitPane>
  )
}

const Contents = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  outline: 1px dashed red;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 744px) {
    flex-direction: row;
  }
`

const LogoWrapper = styled.div`
  position: relative;
  width: 90vw;
  @media only screen and (min-width: 744px) {
    width: 40vw;
  }
`

const StyledRevelryLogo = styled(props => <RevelryLogo {...props} />)``

const Copy = styled.p`
  position: absolute;
  bottom: -1vw;
  left: 0vw;
  font-style: italic;
  font-family: "recoleta";
  font-size: 3vw;
  line-height: 1.3em;
  color: ${palette.white};

  @media only screen and (min-width: 744px) {
    font-size: 1.4vw;
    line-height: 1.2em;
  }
`

const StyledSplitPane = styled(props => <SplitPane {...props} />)<{
  activeProject: string
  color: string
}>`
  background-color: ${props => props.color};
  overflow: hidden;
  grid-row: 1;
  grid-column: ${props => (props.activeProject === "revelry" ? "1" : "2")};
`

const Flip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(-1);
`

const StyledWave = styled(props => <Wave {...props} />).attrs(
  ({ pause }: { pause: boolean }) => ({
    style: {
      animationPlayState: pause ? "paused" : "running",
    },
  })
)<{ size: number; animation: string; pause: boolean }>`
  @keyframes animate_1 {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_2 {
    0% {
      transform: scale(0);
    }
    10% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_3 {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_4 {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(0);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_5 {
    0% {
      transform: scale(0);
    }
    40% {
      transform: scale(0);
    }
    70% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_6 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    80% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_7 {
    0% {
      transform: scale(0);
    }
    60% {
      transform: scale(0);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_8 {
    0% {
      transform: scale(0);
    }
    70% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_9 {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(0);
    }
    100% {
      transform: scale(0.6);
    }
  }
  @keyframes animate_10 {
    0% {
      transform: scale(0);
    }
    90% {
      transform: scale(0);
    }
    100% {
      transform: scale(0.3);
    }
  }
  @keyframes animate_0 {
    0% {
      transform: scale(0.6);
    }
    10% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes animate_00 {
    0% {
      transform: scale(0.3);
    }
    20% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  min-width: 240vw;
  width: 240vw;
  @media only screen and (min-width: 744px) {
    min-width: 150vw;
    width: 150vw;
  }

  transform-origin: right top;
  animation: ${props => props.animation} 60s linear infinite;
  animation-fill-mode: forwards;
`

export default RevelryHeader
