import React from 'react'
import { Svg, Path } from './'


const Wave = ({ color, className, ...props }: { color?: string, className?: string }) => (
  <Svg width="100%" height="100%" viewBox="0 0 289 176" version="1.1" xmlns="http://www.w3.org/2000/svg" {...{ className }} {...props} >
    <g transform="matrix(1.00235,0,0,0.999998,-14.713,-29.6079)">
      <Path {...{ color }} d="M303.001,179.26C283.296,195.662 256.356,205.291 224.412,205.291C106.523,205.291 83.497,92.259 14.679,29.608L303.001,29.608L303.001,179.26Z" />
    </g>
  </Svg>  
)



export default Wave