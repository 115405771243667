import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { BlockRenderer } from "@components/primitives"
import {
  RevelryHeader,
  TangerineHeader,
  Contact,
  TeamPhotosPane,
  TangerineIntroduction,
  RevelryIntroduction,
} from "@components/panels"
import { Layout, RowPane, GridPane } from "@components/layout"
import { Header } from "@components/header"
import { debounce } from "lodash"
import { Helmet } from 'react-helmet'

interface PageProps {
  data: GatsbyTypes.SharedSiteQuery
}

const Index = ({ data }: PageProps) => {
  const { sanitySharedSite } = data
  if (!sanitySharedSite) return <></>
  const activeProject = process.env.GATSBY_PRIMARY_SITE || "tangerine"

  const ref = React.useRef<HTMLDivElement>(null)
  const [flipped, setFlipped] = React.useState(false)

  const handleScroll = (e: any) => {
    const scroller = e.target as HTMLDivElement
    setFlipped(scroller.scrollLeft > scroller.scrollWidth / 4)
  }

  const debouncedScroll = debounce(handleScroll, 50)

  React.useEffect(() => {
    if (!ref.current) return
    ref.current.addEventListener("scroll", debouncedScroll)
    return () => ref.current?.removeEventListener("scroll", debouncedScroll)
  }, [ref])

  const flip = () =>
    ref.current?.scrollTo({
      behavior: "smooth",
      left: flipped ? 0 : (ref.current?.scrollWidth || 2) / 2,
      top: 0,
    })
    
    const title = process.env.GATSBY_PRIMARY_SITE === "revelry" ? 'Revelry' : 'Tangerine'
    
    const meta = [
      { property: "og:type", content: "website" },
      { property: "og:url", content: `${process.env.GATSBY_SITE_BASE_URL}` },
      { charSet: "UTF-8" },
      {
        name: "viewport",
        content:
          "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0",
      },
      { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
      { name: "apple-mobile-web-app-capable", content: "yes" },
      // { name: "theme-color", content: "color(display-p3 0.92 0.36 0.16)" },
    ]

  return (
    <>
      <Helmet {...{ meta, title }} >
      <link
        rel="icon"
        type="image/png"
        href={process.env.GATSBY_PRIMARY_SITE === 'revelry' ? '/revelry-icon.png' : '/tangerine-icon.png'}
        sizes="32x32"
      />      </Helmet>
      <Layout>
        <Header {...{ activeProject, flipped, flip }} />
        <Main {...{ ref }}>
          <HeaderRowPane layer={1}>
            <RevelryHeader inFocus={activeProject === "revelry" || flipped} />
            <TangerineHeader
              inFocus={activeProject === "tangerine" || flipped}
            />
          </HeaderRowPane>

          <RowPane layer={2}>
            <RevelryIntroduction
              {...{ activeProject }}
              introduction={sanitySharedSite._rawRevIntroduction}
              inFocus={
                (activeProject === "revelry" && !flipped) ||
                (activeProject === "tangerine" && flipped)
              }
            />
            <TangerineIntroduction
              {...{ activeProject }}
              introduction={sanitySharedSite._rawTangIntroduction}
              inFocus={
                (activeProject === "tangerine" && !flipped) ||
                (activeProject === "revelry" && flipped)
              }
            />
          </RowPane>

          <TeamPhotosPane
            teamMembers={
              sanitySharedSite.teamMembers as Array<
                Partial<GatsbyTypes.SanityTeamMember>
              >
            }
            layer={3}
            activeProject={
              !flipped
                ? activeProject
                : activeProject === "tangerine"
                ? "revelry"
                : "tangerine"
            }
          />

          <GridPane
            layer={4}
            activeProject={
              !flipped
                ? activeProject
                : activeProject === "tangerine"
                ? "revelry"
                : "tangerine"
            }
          >
            {sanitySharedSite._rawValuesStatements?.map((s: any, i: number) => (
              <div key={`statement_${i}`}>
                <BlockRenderer>{s}</BlockRenderer>
              </div>
            ))}
          </GridPane>

          <StyledRowPane layer={5}>
            <Contact
              thisProject="revelry"
              {...{ activeProject }}
              copy={sanitySharedSite._rawRevContact}
              inFocus={
                (activeProject === "revelry" && !flipped) ||
                (activeProject === "tangerine" && flipped)
              }
            />
            <Contact
              thisProject="tangerine"
              {...{ activeProject }}
              copy={sanitySharedSite._rawTangContact}
              inFocus={
                (activeProject === "tangerine" && !flipped) ||
                (activeProject === "revelry" && flipped)
              }
            />
          </StyledRowPane>
        </Main>
      </Layout>
    </>
  )
}

const Main = styled.div`
  overflow: scroll;
  min-height: 100vh;
  scroll-snap-type: x proximity;
  scroll-snap-points-x: repeat(100vw);
`

const HeaderRowPane = styled(props => <RowPane {...props} />)`
  height: 75vh;
  @media only screen and (min-width: 744px) {
    height: 85vh;
  }
  @media only screen and (min-width: 1120px) {
    height: 70vw;
  }
`

const StyledRowPane = styled(props => <RowPane {...props} />)`
  min-height: 0 !important;
`

export const query = graphql`
  query SharedSite {
    sanitySharedSite(slug: { current: { eq: "shared" } }) {
      _rawTangIntroduction
      _rawRevIntroduction
      teamMembers {
        name
        _rawTitle
        _rawTitleRevelry
        portrait {
          asset {
            url
          }
        }
      }
      _rawValuesStatements
      _rawRevContact
      _rawTangContact
    }
  }
`

export default Index
