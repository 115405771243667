import styled from 'styled-components'

export { default as Lump } from './Lump'
export { default as Pill } from './Pill'
export { default as ShrinkingPill } from './ShrinkingPill'
export { default as RevelryLogo } from './RevelryLogo'
export { default as RevelryLogoV } from './RevelryLogoV'
export { default as TangerineLogo } from './TangerineLogo'
export { default as Wave } from './Wave'


export const Svg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  height: auto;
  text {
  }
`


export const Path = styled.path<{ color?: string }>`
  fill-rule: nonzero;
  fill: ${props => !!props.color ? props.color : 'black'};
`