import * as React from "react"
import styled from "styled-components"
import { TangerineLogo, Pill } from "@components/images"
import { palette } from "@theme"

interface TangerineHeaderProps {
  visible: boolean
  scrolled: boolean
  left?: boolean
  className?: string
}

const TangerineHeader = ({
  visible,
  className,
  scrolled,
  left,
  ...props
}: TangerineHeaderProps) => {
  const scrollToTop = () => window.scrollTo({ behavior: "smooth", top: 0 })

  return (
    <Wrapper
      {...{ visible, left, scrolled, className }}
      {...props}
      onClick={() => scrollToTop()}
    >
      <StyledTangerineLogo
        color={palette.tangerine}
        colorDot={palette.rhubarb}
      />
      <StyledPill color={palette.peach} />
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  visible: boolean
  scrolled: boolean
  left?: boolean
}>`
  position: absolute;
  z-index: 2;
  ${props => (props.left ? "left: 2vw;" : "right: 4vw;")}
  top: -4vw;
  width: 30vw;
  height: 12vw;
  transform: ${props =>
    props.visible && props.scrolled ? "translateY(0)" : "translateY(-15vh)"};
  @media only screen and (min-width: 744px) {
    width: 18vw;
    height: 12vw;
    ${props => (props.left ? "left: 2vw;" : "right: 2vw;")}
    transform: ${props =>
      props.visible && props.scrolled ? "translateY(0)" : "translateY(-15vh)"};
  }
  transform-origin: center center;
  transition: transform 0.3s ease-in-out, height 0.3s ease-in-out,
    width 0.3s ease-in-out;
  cursor: pointer;
`

const StyledTangerineLogo = styled(props => <TangerineLogo {...props} />)`
  position: absolute;
  z-index: 3;
  left: 2vw;
  width: 26vw;
  top: 7vw;
  @media only screen and (min-width: 744px) {
    left: 1.5vw;
    width: 15vw;
    top: 5.5vw;
  }
  transition: top 0.3s ease-in-out, width 0.3s ease-in-out;
`

const StyledPill = styled(props => <Pill {...props} />)`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
`

export default TangerineHeader
